import './onlineCheck';
import { jwtDecode } from 'jwt-decode';
import { decodeEmojis } from '@ai/shared/strings';
import {
  embedSchema,
  type EmbedConfig,
  
  tokenSchema,
  type TokenConfig,
} from '@ai/shared/janet';
import {
  get as getSession,
  set as setSession,
} from './session';
import {
  get as getPrefersColorScheme,
  watch as watchPrefersColorScheme,
} from './prefersColorSchem';
import {
  demo as demoAtom,
  token as tokenAtom,
  isOpen as isOpenAtom,
  domainId as domainIdAtom,
  sessionId as sessionIdAtom,
  size as sizeAtom,
  margin as marginAtom,
  colorScheme as colorSchemeAtom,
  placeholder as placeholderAtom,
  poweredBy as poweredByAtom,
  zIndex as zIndexAtom,
  welcomeMessage as welcomeMessageAtom,
  welcomeDelay as welcomeDelayAtom,
  hasGreeted as hasGreetedAtom,
  conversationCap as conversationCapAtom,
  conversationCapMessage as conversationCapMessageAtom,
  leadEnabled as leadEnabledAtom,
  leadPosition as leadPositionAtom,
  leadNameEnabled as leadNameEnabledAtom,
  leadEmailEnabled as leadEmailEnabledAtom,
  leadPhoneEnabled as leadPhoneEnabledAtom,
  leadHeading as leadHeadingAtom,
  leadMessage as leadMessageAtom,
  hasLeadCaptured as hasLeadCapturedAtom,
  messages as messagesAtom,
  isGreetingClosed as isGreetingClosedAtom,
  isValid as isValidAtom,
} from '../index';

const getScript = function() {
  return document.getElementById('secre_tary') as HTMLScriptElement || document.createElement('script');
};

export const getDebug = function(): boolean {
  const script = getScript();
  return script.getAttribute('data-debug') === 'true';
};

export const getDemo = function(): boolean {
  const script = getScript();
  return script.getAttribute('data-demo') === 'true';
};

export const getToken = function(): string {
  const script = getScript();
  return script.getAttribute('data-token') || '';
};

export const getConfig = function(): string {
  const script = getScript();
  return script.getAttribute('data-config') || '';
};

const isSameOrigin = (hostnameOrigin: string) => {
  const { hostname } = globalThis.location;
  return hostnameOrigin === hostname;
};

const parseToken = function(token: string): TokenConfig | null {
  try {
    const data = jwtDecode(token) as unknown;
    const result = tokenSchema.safeParse(data);
    if (result.success) return result.data;
  } catch {}

  return null;
};

const parseConfig = function(): EmbedConfig | null {
  try {
    const str = getConfig();
    const data = JSON.parse(atob(str));
    const result = embedSchema.safeParse(data);
    if (result.success) return result.data;
  } catch {}

  return null;
};

export const initialize = function({
  debug: isDebug = getDebug(),
  token = getToken(),
  config: manualConfig,
  demo: isDemo = getDemo(),
}: {
  debug?: boolean;
  token?: string;
  config?: EmbedConfig;
  demo?: boolean;
}) {
  demoAtom.set(isDemo);

  const tokenConfig = parseToken(token);
  if (!tokenConfig) return;
  
  if (!isDebug && !isSameOrigin(tokenConfig.url)) return;
  
  const config = isDebug && manualConfig ? manualConfig : parseConfig();
  if (!config) return;

  tokenAtom.set(token);
  domainIdAtom.set(tokenConfig.domainId);

  sizeAtom.set(config.size);
  marginAtom.set(config.margin);

  let disposeWatchPrefersColorScheme: () => void;
  if (config.colorScheme === 'auto') {
    colorSchemeAtom.set(getPrefersColorScheme());
    disposeWatchPrefersColorScheme = watchPrefersColorScheme();
  } else {
    colorSchemeAtom.set(config.colorScheme);
  }

  placeholderAtom.set(decodeEmojis(config.placeholder));
  poweredByAtom.set(config.poweredBy);
  zIndexAtom.set(config.zIndex);
  welcomeMessageAtom.set(decodeEmojis(config.welcomeMessage));
  welcomeDelayAtom.set(config.welcomeDelay);
  conversationCapAtom.set(config.limit);
  conversationCapMessageAtom.set(config.capMessage);

  leadEnabledAtom.set(config.leadEnabled);
  leadPositionAtom.set(config.leadPosition);
  leadNameEnabledAtom.set(config.name);
  leadEmailEnabledAtom.set(config.email);
  leadPhoneEnabledAtom.set(config.phone);
  leadHeadingAtom.set(decodeEmojis(config.leadHeading));
  leadMessageAtom.set(decodeEmojis(config.leadMessage));

  const session = getSession();

  sessionIdAtom.set(session.id);
  isOpenAtom.set(session.isOpen);
  messagesAtom.set(session.messages);
  hasGreetedAtom.set(session.hasGreeted);
  hasLeadCapturedAtom.set(session.hasLeadCaptured);
  isGreetingClosedAtom.set(session.isGreetingClosed);

  const saveSession = () => {
    setSession({
      id: sessionIdAtom.get(),
      isOpen: isOpenAtom.get(),
      messages: messagesAtom.get(),
      hasGreeted: hasGreetedAtom.get(),
      hasLeadCaptured: hasLeadCapturedAtom.get(),
      isGreetingClosed: isGreetingClosedAtom.get(),
    });
  };

  const disposeSessionId = sessionIdAtom.on('change', saveSession);
  const disposeMessages = messagesAtom.on('change', saveSession);
  const disposeisOpen = isOpenAtom.on('change', saveSession);
  const disposeHasGreetedAtom = hasGreetedAtom.on('change', saveSession);
  const disposeHasLeadCaptured = hasLeadCapturedAtom.on('change', saveSession);
  const disposeIsGreetingClosed = isGreetingClosedAtom.on('change', saveSession);

  isValidAtom.set(true);

  return () => {
    disposeSessionId();
    disposeMessages();
    disposeisOpen();
    disposeHasGreetedAtom();
    disposeHasLeadCaptured();
    disposeIsGreetingClosed();
    disposeWatchPrefersColorScheme?.();
  };
};
