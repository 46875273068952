import { type FormEvent, useCallback } from 'react';
import { css } from '../css';
import Input from './lead-gen-input';
import Button from './lead-gen-button';
import leadGen from './lead-gen.css?inline';
import onSendLeadGen from '../actions/onSendLeadGen';
import {
  isOpen as isOpenAtom,
  leadNameEnabled as leadNameEnabledAtom,
  leadEmailEnabled as leadEmailEnabledAtom,
  leadPhoneEnabled as leadPhoneEnabledAtom,
  leadPosition as leadPositionAtom,
  leadHeading as leadHeadingAtom,
  leadMessage as leadMessageAtom,
} from '../store';

css(leadGen);

export default function LeadGenView() {
  const [isOpen] = isOpenAtom.useState();
  
  const [position] = leadPositionAtom.useState();
  const [isNameEnabled] = leadNameEnabledAtom.useState();
  const [isEmailEnabled] = leadEmailEnabledAtom.useState();
  const [isPhoneEnabled] = leadPhoneEnabledAtom.useState();

  const [leadHeading] = leadHeadingAtom.useState();
  const [leadMessage] = leadMessageAtom.useState();

  const isPre = position === 'pre';

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = new FormData(e.target as HTMLFormElement);

    const {
      name = '',
      email = '',
      phone = '',
    } = Object.fromEntries(data) as {
      name?: string;
      email?: string;
      phone?: string;
    };

    onSendLeadGen({
      name,
      email,
      phone,
    });
  }, []);

  return (
    <form
      data-open={ isOpen }
      className="lead-gen"
      onSubmit={ onSubmit }
    >
      <div>
        <p>
          { leadHeading }
        </p>
        <p>
          { leadMessage }
        </p>
      </div>
      { isNameEnabled && (
        <Input
          type="text"
          name="name"
          placeholder="name"
          minLength={ 3 }
          maxLength={ 256 }
          required
        />
      ) }
      { isEmailEnabled && (
        <Input
          type="email"
          name="email"
          placeholder="email"
          maxLength={ 256 }
          required
        />
      ) }
      { isPhoneEnabled && (
        <Input
          type="tel"
          name="phone"
          placeholder="phone: 123-456-7890"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          maxLength={ 256 }
          required
        />
      ) }
      <Button type="submit">
        { isPre ? 'Start Conversation' : 'Send' }
      </Button>
    </form>
  );
};