import noop from '../utils/noop';
import styles from './send-button.css?inline';
import { css } from '../css';
import { Send } from 'lucide-react';

css(styles);

// FUTURE: sending state/spinner
export default function SendButton({
  disabled = false,
  onClick = noop,
}) {
  return (
    <button
      className="send-button"
      disabled={ disabled }
      onClick={ onClick }
    >
      <Send />
    </button>
  );
};