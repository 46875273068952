import { useCallback, useEffect, useRef, type ReactNode } from 'react';
import styles from './assistant-container.css?inline';
import { css } from '../css';
import {
  isOpen as isOpenAtom,
} from '../store';

css(styles);

export default function AssistantContainer({ children }: { children: ReactNode }) {
  const [open] = isOpenAtom.useState();
  const ref = useRef<HTMLDivElement>(null);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    const target = event.target as HTMLElement;
    const isInput =
      target.tagName === 'INPUT' ||
      target.tagName === 'SELECT' ||
      target.tagName === 'TEXTAREA';

    const isDescendantOfWrapper = ref.current?.contains(target);

    if (open && event.key === 'Escape' && (!isInput || isDescendantOfWrapper)) {
      isOpenAtom.set(false);
    }
  }, [open]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (!ref.current) return;
    
    const ta = ref.current.querySelector('textarea');
    if (!ta) return;

    if (!open) ta.blur();
    if (open) ta.focus();
  }, [open]);

  return (
    <div className="assistant-container" data-open={ open } ref={ ref }>
      { children }
    </div>
  );
};