import gsap from 'gsap';
import { memo, useMemo, CSSProperties, forwardRef, Ref } from 'react';
import { css } from '../css';
import styles from './empathy.css?inline';
import { useEmpathy } from './empathy.hooks';
import { range } from '../utils/range';

css(styles);

const ellipsis = range(30);

// FUTURE: chat config for colors
const colors = [
  '#172662',
  '#3c52d9',
  '#2ef2ff',
  '#FFC43D',
  '#3c52d9',
  '#EF476F',
  '#bedf7c',
  '#172662',
];

const Ellipse = ({
  idx,
  colorInterp,
}: {
  idx: number,
  colorInterp: ReturnType<typeof gsap.utils.interpolate>,
}) => {
  const count = idx + 1;
  return (
    <ellipse
      cx="400"
      cy="300"
      rx="80"
      ry="80"
      style={ {
        '--opacity': 1 - count / (ellipsis.length),
        '--stroke': colorInterp(count / (ellipsis.length)),
      } as CSSProperties }
    />
  );
};

export default memo(forwardRef(function Empathy(_, handle: Ref<unknown> | undefined) {
  const ref = useEmpathy(handle);
  const colorInterp = useMemo(() => gsap.utils.interpolate(colors), []);

  return (
    <svg
      ref={ ref }
      className="empathy"
      viewBox="0 0 800 600"
    >
      { ellipsis.map(idx => (
        <Ellipse
          key={ idx }
          idx={ idx }
          colorInterp={ colorInterp }
        />
      )) }
    </svg>
  );
}));