import { createRoot } from 'react-dom/client';
import fontStyles from './janet.font.css?inline';

const createContainer = () => {
  const container = document.createElement('div');
  container.setAttribute('id', 'janet');
  return container;
};

const generateFontStyles = () => {
  const style = document.createElement('style');
  style.innerHTML = fontStyles;
  return style;
};

export const createShadowDom = function() {
  const createShadowContext = (container: HTMLElement) => {
    const shadow = container.attachShadow({ mode: 'open' });
    
    // fonts need to go in the head/body (not the shadow dom)
    // https://robdodson.me/posts/at-font-face-doesnt-work-in-shadow-dom/
    const style = generateFontStyles();
    try {
      document.head.appendChild(style);
    } catch {
      try {
        document.body.prepend(style);
      } catch {}
    }
    
    return shadow;
  };

  // create root container where react element will be inserted
  const container = document.getElementById('janet') || createContainer();
  
  // attach shadow DOM to container
  const shadowRoot = container.shadowRoot || createShadowContext(container);

  // shadow DOM as react root
  // @ts-ignore
  const root = container.root || (container.root = createRoot(shadowRoot));

  // append to the dom if it's not already there
  if (!container.parentElement) {
    document.body.appendChild(container);
  }

  return root;
};