import { useRef, useMemo } from 'react';
import styles from './message-bubble.css?inline';
import { css } from '../css';
import LoadingEllipsis from './loading-ellipsis';
import { type Message, AI } from '@ai/shared/janet';
import Typewriter from './typewriter';
import TimeAgo from './time-ago';
import DynamicTextRender, {
  create as createDynamicText,
} from './dynamic-text';

css(styles);

const isValidDate = (d: Date) => {
  return d instanceof Date && !Number.isNaN(d.getTime());
};

export default function MessageBubble({
  role,
  loading,
  createdAt,
  text,
}: Message) {
  const loadingRef = useRef<boolean>(loading);
  const isAi = role === AI;
  const wentFromLoadingToNotLoading = loadingRef.current === true && loading === false;
  const shouldTypewriter = isAi && wentFromLoadingToNotLoading;
  const displayText = useMemo(() => createDynamicText(text), [text]);

  return (
    <div
      className={ `message-bubble ${isAi ? 'message-bubble--assistant' : 'message-bubble--user'}` }
      data-loading={ loading }
    >
      { role === AI && <LoadingEllipsis loading={ loading } /> }
      { shouldTypewriter ? (
        <Typewriter
          className="message-bubble__text"
          text={ displayText }
        />
      ) : (
        <span className="message-bubble__text">
          { isAi ? <DynamicTextRender text={ displayText } /> : text }
        </span>
      ) }
      { isValidDate(createdAt) && (
        <TimeAgo
          className="message-bubble__time"
          date={ createdAt }
        />
      ) }
    </div>
  );
};