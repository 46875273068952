import { useState } from 'react';
import { css } from '../css';
import styles from './loading-ellipsis.css?inline';

css(styles);

export default function LoadingEllipsis({ loading }: { loading: boolean; }) {
  const [render, set] = useState(loading);
  
  if (!render) return null;

  return (
    <div
      className="loading-ellipsis"
      data-loading={ loading }
      onTransitionEnd={ () => set(false) }
    >
      <span>
        <span />
        <span />
        <span />
        <span />
        <span />
      </span>
    </div>
  );
};