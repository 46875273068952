import logo from './logo.css?inline';
import { css } from '../css';

css(logo);

export default function Logo({ className }: { className?: string; }) {
  return (
    <svg className={ `logo ${className || ''}` } viewBox="0 0 416.3 323">
      <defs>
        <filter id="__secretary-logo-filter" x="0" y="0">
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="5" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="15" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
      <path d="M144.9,30.9c28.6-36.1,82.6-41.7,117.3-10.8c3.6,3.2,7.1,7.3,10.5,11.2c0.2,0.2,0.5,0.4,0.9,0.3
        c2-0.2,4.4-0.7,5.9-0.8c21.5-2,40.5,7.1,51.8,25.1c9.2,14.6,11,33.9,4,50.4c-0.1,0.2-0.2,0.3-0.5,0.1l-8.9-5
        c-0.1-0.1-0.2-0.3-0.1-0.4c5.3-15.9,2.4-33-9.1-45.3c-10.6-11.4-25-15.1-40.1-12.7c-3,0.5-8.3,2.3-10.3-0.6
        c-24.8-36.6-77.6-41.2-108.2-8.9c-2.7,2.9-5.2,6.4-7.6,9.7c-0.9,1.2-2.1,1.7-3.6,1.4c-10.5-2.4-20-3-30.2,1
        c-17,6.6-26.3,20.3-27.9,38.4c0,0.3-0.2,0.5-0.4,0.7l-9.7,6.1c-0.2,0.1-0.4,0-0.5-0.1c0,0,0-0.1,0-0.1c-2.8-23.1,9.7-45.3,31-54.8
        c10.9-4.9,22.7-6.1,34.5-4.2C144.2,31.4,144.6,31.3,144.9,30.9z"/>
      <path d="M77.4,174.7c0.2,0,0.3-0.2,0.3-0.3c0-0.1,0-0.1-0.1-0.2c-28.1-32.9-6.8-79.6,34-85.1c7.5-1,15.8-0.6,25,1.3
        c0.4,0.1,0.6,0,0.9-0.3c42-47.1,111.7-38.2,143.8,14.9c0.4,0.6,1,0.9,1.7,0.9c17.7-0.7,31.8,0.8,46.4,11.7
        c15.1,11.3,25.1,29.8,25.8,48.7c0.4,11-2.3,20.6-6.1,30.8c-2.8,7.4,1.6,15.3,7.2,19.8c6,4.8,12.9,7.3,20.6,7.5
        c11.1,0.3,22.2,0.4,33.2,0.2c4.1,0,6.2,1.9,6.2,5.7c0,4.8-3.5,5.8-7.8,5.8c-5,0.1-9.9,0.1-14.9,0.2c-18.8,0.3-36.8-0.3-49.7-15.7
        c-5.6-6.7-9.6-17.6-6-26.5c2.5-6.1,4-10.6,4.7-13.5c7-31.3-15.7-63.1-48.5-63.4c-4.2,0-9.4,0.1-15.5,0.5c-3.8,0.2-7.2-7.4-9.1-10.2
        c-18.6-27.3-48.7-42-81.7-34.4C172.9,76.7,160,84,149,95.2c-2.1,2.1-4.2,4.3-6.2,6.5c-0.9,1-2.2,1.3-3.9,0.9
        c-4.1-0.8-8.2-1.5-12.3-2c-16.8-2-33.5,4-43.3,17.8c-7.4,10.5-9,24.3-4.6,36.4c3,8.4,8.6,15,15.5,20.8c0.5,0.4,0.8,1.2,1,2.2
        c0.5,3.3-0.9,5.3-4.1,5.9c-9.2,1.8-16.7,2.6-26.7,6.5c-17.5,6.8-30.3,22.9-32.7,42c-0.4,2.9-2.7,4.2-5.8,4.2
        c-8-0.1-15.1,0.1-20.5-0.2c-8.3-0.5-6.6-11.9,2.5-11.5c2.3,0.1,6.7,0,13-0.3c0.3,0,0.6-0.2,0.6-0.5C29,197.1,49.6,179.1,77.4,174.7z
        "/>
      <path d="M226.7,118.8c5.3,12.4,13,22.2,23.1,29.3c13.2,9.4,29.8,10,32.8,29.7c3.8,25,1.5,49.9-5.6,74.8
        c-6.5,22.6-15.8,40.2-34.7,54.1c-6.5,4.8-12.5,8.8-17.8,12c-11.9,7.2-22.9,4.8-34.2-2.1c-16-9.8-29.9-19.4-39.5-37
        c-5.1-9.3-9-19-11.8-29.2c-5.1-18.6-7.3-37.1-6.6-55.5c0.7-19,6.4-35.9,24.7-44.8c22.9-11.1,51.3-10.1,68.8-31.3
        C226.2,118.3,226.5,118.3,226.7,118.8z M174.3,198.5c7.3,2.8,12.1,5.1,18.1,7.9c0.5,0.2,0.6,0.1,0.3-0.3
        c-6.3-9.1-12.3-16.6-22.8-20.7c-11.7-4.6-24.4-3.1-33.4,6.3c-0.8,0.8-0.6,1.2,0.5,1.1C150,191.7,162.2,193.8,174.3,198.5z
        M280.7,191.7c-4.7-4.9-10.5-7.7-17.3-8.4c-16.6-1.6-30.6,9.2-39,22.8c-0.6,1-0.4,1.2,0.6,0.6c17.2-9.2,35.6-15.5,55.4-14.3
        c0.3,0,0.5-0.2,0.5-0.4C280.8,191.9,280.8,191.8,280.7,191.7z M142,208.2c13.4,15.5,27.9,10.6,44.5,5.4c1.1-0.3,1.2-0.9,0.4-1.7
        c-4.4-4.4-9.3-6.9-14.7-7.6c-8-1.1-13,0.5-21.6,3.3c-2,0.6-4.8,0.7-8.5,0.3C141.8,207.8,141.7,207.9,142,208.2z M260.9,205.6
        c-9.3-3.9-24.5-2.3-30.9,7.3c-0.2,0.3-0.1,0.6,0.3,0.7c14.3,3.7,26.4,9.7,39.9-0.8c1.9-1.5,3.7-2.9,5.4-4.3c0.2-0.1,0.2-0.4,0.1-0.6
        c-0.1-0.1-0.2-0.2-0.4-0.2C270.4,208.3,265.6,207.6,260.9,205.6z M200.8,264.6c6.6,2.8,13,2.3,19-1.5c1.1-0.7,1.7-2.1,1.3-3.4
        l-0.3-1.2c-0.4-1.5-1.3-1.9-2.7-1.2c-4.1,2.1-9,5.1-12.3,3.3c-2.2-1.2-4.4-2.4-6.6-3.7c-0.3-0.2-0.6-0.2-0.9,0
        C193.8,259.8,197.9,263.4,200.8,264.6z M200.1,296.9c11.9,6,24.9-2.8,31.2-12.5c0.1-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0
        c-3.6-1.3-7.1-2.7-10.6-4.1c-4.7-1.9-6.7-0.8-11.2-0.1c-3.3,0.6-6.8-2-9.9-0.8c-4.4,1.7-8.7,3.2-12.8,4.6c-0.6,0.2-0.7,0.5-0.3,1
        C189.9,290,194.5,294.1,200.1,296.9z"/>
      <path d="M297.6,213.6c-1.5,3.9-3.4,8.1-7,9.7c-1.7,0.8-2.2,0.3-1.6-1.5c3.4-9.5,5.4-19.4,6-29.5
        c0.1-1.4,0.4-1.4,1.1-0.2C299.9,199.1,300.4,206.2,297.6,213.6z"/>
      <path d="M128.5,223.4c-7-1.1-9.8-13-9.6-18.6c0-1.3,0.6-2.3,1.6-3l2.2-1.7c1-0.8,1.5-0.5,1.6,0.7
        c0.5,6.6,1.3,11.1,2.4,13.7c1,2.4,1.9,5.1,2.6,8c0.1,0.4-0.1,0.8-0.5,0.9C128.7,223.4,128.6,223.4,128.5,223.4z"/>
    </svg>
  );
};