import {
  AI,
  HUMAN,
  type Message,
} from '@ai/shared/janet';
import { handleDemo } from './handleDemo';
import { nanoid } from 'nanoid';
import { chat } from '../api';
import wait from '../utils/wait';
import {
  demo as demoAtom,
  loading as loadingAtom,
  messages as messagesAtom,
} from '../store';

const toHistory = (history: Message[]): [number, string][] => {
  return history
    .filter(({ loading }) => !loading)
    .map(({ role, text }) => [role, text]);
};

const sendMessage = async function(text: string) {
  const id = nanoid();
  const messages = messagesAtom.get();
  const userMessage: Message = {
    id: nanoid(),
    text,
    createdAt: new Date(),
    loading: false,
    role: HUMAN,
  };

  const request = chat({
    id,
    text,
    history: toHistory(messages),
  });

  const aiMessage: Message = {
    id,
    text: '',
    createdAt: new Date(),
    loading: true,
    role: AI,
  };

  messagesAtom.set([...structuredClone(messagesAtom.get()), userMessage]);

  await wait(1000); // enforce a delay before showing the AI message loading

  messagesAtom.set([...structuredClone(messagesAtom.get()), aiMessage]);

  const data = await request;
  if (!data) return; // FUTURE: handle if no data comes back: app error state

  const currentMessages = messagesAtom.get();
  const currentMesssage = currentMessages.find(({ id }) => id === data.messageId);
  if (!currentMesssage) return console.error('secre.tary.ai: message error', data);

  currentMesssage.loading = false;
  currentMesssage.text = data.message;

  messagesAtom.set(structuredClone(currentMessages));
};

export default async function onSendMessage(text: string) {
  loadingAtom.set('loading');
  try {
    if (demoAtom.get()) {
      await handleDemo(text);
    } else {
      await sendMessage(text);
    }
  } finally {
    loadingAtom.set('done');
  }
}