import {  
  messages as messagesAtom,
  conversationCap as conversationCapAtom,
} from '../store';

export default function useIsAtCap() {
  // all messages are in pairs with a question followed by an answer (presumably)
  // so we can get the current count of AI messages generated with a bit of math
  //
  // this is the same logic the server uses to see if the conversation is at cap
  const [limit] = conversationCapAtom.useState();
  const [messages] = messagesAtom.useState();
  const aiMessageCount = ~~(messages.length / 2);
  return aiMessageCount >= limit;
};