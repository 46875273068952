import { useRef, useState } from 'react';
import Container from './assistant-container';
import TextAreaAutoResize from './textarea-autoresize';
import SendButton from './send-button';
import onSendMessage from '../actions/onSendMessage';
import useIsAtCap from '../hooks/useIsAtCap';
import {
  loading as loadingAtom,
  placeholder as placeholderAtom,
} from '../store';

export default function Assistant() {
  const limitReached = useIsAtCap();
  const [placeholder] = placeholderAtom.useState();
  const [loadingState] = loadingAtom.useState();
  const isLoading = loadingState === 'loading';
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [text, setText] = useState('');
  
  const onSend = () => {
    if (limitReached) return;
    if (isLoading) return;

    onSendMessage(text);
    setText('');

    textareaRef.current?.focus();
  };

  const disabled = isLoading || text.length < 3 || text.length > 256;

  return (
    <Container>
      <TextAreaAutoResize
        ref={ textareaRef }
        value={ text }
        placeholder={ placeholder }
        maxLength={ 256 }
        minLength={ 3 }
        onChange={ e => {
          if (isLoading) return;
          // @ts-ignore
          setText(e.target.value);
        } }
        onKeyDown={ e => {
          if (isLoading) return;
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSend();
          }
        } }
      />
      <SendButton
        disabled={ disabled }
        onClick={ onSend }
      />
    </Container>
  );
};