import { useState, useEffect } from 'react';
import noop from '../utils/noop';
import sample from '../utils/sample';
import wait from '../utils/wait';
import styles from './typewriter.css?inline';
import { css } from '../css';
import { useIsMounted } from '../hooks/useIsMounted';
import DynamicTextRender, { type DynamicText as DynamicText } from './dynamic-text';

css(styles);

const timescale = 2.5;
const delays = [40, 45, 50, 55, 60].map(n => n * timescale);

export type TypewriterProps = {
  text: DynamicText;
  className: string;
  onTypewriterStart?: () => void;
  onTypewriterEnd?: () => void;
}

const useTypewriter = function(values: DynamicText, onTypewriterStart = noop, onTypewriterEnd = noop) {
  const [value, setValue] = useState<DynamicText>(() => []);
  const [done, setDone] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    onTypewriterStart();
  
    const start = async function() {      
      for (let idx = 0; idx < values.length; idx++) {
        const delay = sample(delays) as number;
        await wait(delay);

        if (!isMounted()) return;

        setValue(values.slice(0, idx + 1));
      }

      setDone(true);
      onTypewriterEnd();
    };

    start();
  }, [values, isMounted]);

  return {
    value,
    done,
  };
};

export default function Typewriter({
  text,
  className,
  onTypewriterStart,
  onTypewriterEnd,
}: TypewriterProps) {
  const { value, done } = useTypewriter(
    text,
    onTypewriterStart,
    onTypewriterEnd,
  );
  return (
    <span className={ `${className} typewriter` } data-done={ done }>
      <DynamicTextRender text={ value } />
      <span>
        &#x25cf;
      </span>
    </span>
  );
};