import {
  leadGen,
} from '../api';
import {
  loading as loadingAtom,
  hasLeadCaptured as hasLeadCapturedAtom,
} from '../store';

export default async function onSendLeadGen(payload: { name: string; email: string; phone: string; }) {
  hasLeadCapturedAtom.set(true);
  loadingAtom.set('loading');
  try {
    await leadGen(payload);
  } finally {
    loadingAtom.set('done');
  }
};