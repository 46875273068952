import {
  type LeadGenRequest,
  type ChatRequest,
  type ChatResponse,
} from '@ai/shared/api';
import {
  API_CHAT_URL,
  API_LEAD_GEN_URL,
} from '../constants';
import {
  token as tokenAtom,
  sessionId as sessionIdAtom,
} from '../store';

export const chat = async function({
  id,
  text,
  history,
}: {
  id: string;
  text: string;
  history: [number, string][];
}): Promise<ChatResponse | null> {
  const payload = {
    sessionId: sessionIdAtom.get(),
    messageId: id,
    text,
    history,
  } satisfies ChatRequest;

  try {
    const res = await fetch(API_CHAT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${tokenAtom.get()}`,
      },
      body: JSON.stringify(payload)
    });
    return await res.json();
  } catch (err) {
    console.error('secre.tary.ai: api error', err);
  }
  return null;
};

type LeadFormData = {
  name: string;
  email: string;
  phone: string;
};

export const leadGen = async function(formData: LeadFormData) {
  const payload = {
    sessionId: sessionIdAtom.get(),
    ...formData,
  } as LeadGenRequest;

  try {
    const res = await fetch(API_LEAD_GEN_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${tokenAtom.get()}`,
      },
      body: JSON.stringify(payload)
    });
    return await res.json();
  } catch (err) {
    console.error('secre.tary.ai: api error', err);
  }
  return null;
};