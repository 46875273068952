import {
  type Message,
  
  AI,
  HUMAN,
} from '@ai/shared/janet';
import { nanoid } from 'nanoid';
import wait from '../utils/wait';
import {
  messages as messagesAtom,
} from '../store';

export const handleDemo = async function(text: string) {
  const id = nanoid();
  const userMessage: Message = {
    id: nanoid(),
    text,
    createdAt: new Date(),
    loading: false,
    role: HUMAN,
  };

  const aiMessage: Message = {
    id,
    text: '',
    createdAt: new Date(),
    loading: true,
    role: AI,
  };

  messagesAtom.set([...structuredClone(messagesAtom.get()), userMessage]);

  await wait(1000); // enforce a delay before showing the AI message loading

  messagesAtom.set([...structuredClone(messagesAtom.get()), aiMessage]);

  await wait(1000);
  
  const currentMessages = messagesAtom.get();
  const currentMesssage = currentMessages.find(msg => msg.id === id)!;

  currentMesssage.loading = false;
  currentMesssage.text = `Thank you for chatting! I'm in demo mode so I can't respond to your message, but you can see how the chat works. If you want to chat with me, you can sign up for a free account at [secre.tary.ai](https://secre.tary.ai)`;

  messagesAtom.set(structuredClone(currentMessages));
};