import { TEST_KEY } from '../../constants';

const MemoryStorage = function() {
  const valuesMap = new Map();
  return {
    getItem(key: string) {
      const stringKey = String(key);
      if (valuesMap.has(key)) {
        return String(valuesMap.get(stringKey));
      }
      return null;
    },

    setItem(key: string, val: any) {
      valuesMap.set(String(key), String(val));
    },

    removeItem(key: string) {
      valuesMap.delete(key);
    },

    clear() {
      valuesMap.clear();
    },

    key(i: number) {
      if (arguments.length === 0) {
        throw new TypeError(`Failed to execute 'key' on 'Storage': 1 argument required, but only 0 present.`); // this is a TypeError implemented on Chrome, Firefox throws Not enough arguments to Storage.key.
      }
      const arr = Array.from(valuesMap.keys());
      return arr.at(i);
    },

    get length() {
      return valuesMap.size;
    },
  };
};

const isStorageAvailable = (store: any) => {
  try {
    store.setItem(TEST_KEY, TEST_KEY);
    store.removeItem(TEST_KEY);
    return true;
  } catch (e) {
    return false;
  }
};

const storage = isStorageAvailable(globalThis.localStorage)
  ? globalThis.localStorage
  : isStorageAvailable(globalThis.sessionStorage)
    ? globalThis.sessionStorage
    : MemoryStorage();

export default storage as ReturnType<typeof MemoryStorage>;