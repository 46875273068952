import { z } from 'zod';

export const AI = 0;
export const HUMAN = 1;

export type Sizes = 'mi' | 'sm' | 'md' | 'lg' | 'xl';
export type Margins = 'mi' | 'sm' | 'md' | 'lg' | 'xl';
export type ColorSchemes = 'light' | 'dark' | 'auto';
export type LeadPosition = 'pre' | 'post';

export const tokenSchema = z.object({
  domainId: z.string(),
  url: z.string(),
});

export type TokenConfig = z.infer<typeof tokenSchema>;

export const embedSchema = z.object({
  size: z.enum(['mi', 'sm', 'md', 'lg', 'xl']),
  margin: z.enum(['mi', 'sm', 'md', 'lg', 'xl']),

  colorScheme: z.enum(['light', 'dark', 'auto']),
  placeholder: z.string(),
  poweredBy: z.boolean(),
  zIndex: z.number(),
  welcomeMessage: z.string(),
  welcomeDelay: z.number(),
  limit: z.number(),
  capMessage: z.string(),

  leadEnabled: z.boolean(),
  leadPosition: z.enum(['pre', 'post']),
  leadHeading: z.string(),
  leadMessage: z.string(),
  name: z.boolean(),
  email: z.boolean(),
  phone: z.boolean(),
});

export type EmbedConfig = z.infer<typeof embedSchema>;

export const messageSchema = z.object({
  id: z.string(),
  text: z.string(),
  createdAt: z.date(),
  loading: z.boolean(),
  role: z.union([z.literal(AI), z.literal(HUMAN)]),
});

export type Message = z.infer<typeof messageSchema>;