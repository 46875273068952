import { type ButtonHTMLAttributes } from 'react';
import { css } from '../css';
import leadGenButton from './lead-gen-button.css?inline';

css(leadGenButton);

export default function LeadGenButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className="lead-gen-button"
      { ...props }
    />
  );
};