import type { EmbedConfig } from '@ai/shared/janet';
import { memo, useEffect, type CSSProperties } from 'react';
import janetStyles from './janet.css?inline';
import { css, serialize } from './css';
import App from './app';
import {
  initialize,
  
  size as sizeAtom,
  margin as marginAtom,
  zIndex as zIndexAtom,
  isValid as isValidAtom,
  isOnline as isOnlineAtom,
} from './store';

css(janetStyles);

const Style = memo(() => (
  <style>
    { serialize() }
  </style>
));

const Main = () => {
  const [z] = zIndexAtom.useState();
  const [sz] = sizeAtom.useState();
  const [mg] = marginAtom.useState();
  return (
    <main
      className="janet"
      data-size={ sz }
      data-margin={ mg }
      style={ {
        '--z-janet': z,
      } as CSSProperties }
    >
      <App />
    </main>
  );
};

export default memo(function Janet({
  token,
  config,
  debug,
  demo,
}: {
  debug?: boolean;
  token?: string;
  config?: EmbedConfig;
  demo?: boolean;
}) {
  const [isValid] = isValidAtom.useState();
  const [isOnline] = isOnlineAtom.useState();
  
  useEffect(() => {
    return initialize({
      debug,
      token,
      config,
      demo,
    });
  }, [debug, token, config, demo]);

  if (!isValid) return null;
  if (!isOnline) return null;

  return (
    <>
      <Style />
      <Main />
    </>
  );
});