export const Signal = function() {
  const events = new Map<string, Function[]>();
  
  return {
    on(eventName: string, fn: Function) {
      if (!events.has(eventName)) events.set(eventName, []);
      events.get(eventName)!.push(fn);

      return () => {
        if (!events.has(eventName)) return;

        events.set(
          eventName,
          events.get(eventName)!.filter(eventFn => fn !== eventFn)
        );
      };
    },
    emit(eventName: string, data: unknown) {
      if (!events.has(eventName)) return;

      for (const fn of events.get(eventName)!) {
        fn(data);
      }
    },
  };
};