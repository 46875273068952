import {  
  leadEnabled as leadEnabledAtom,
  leadNameEnabled as leadNameEnabledAtom,
  leadEmailEnabled as leadEmailEnabledAtom,
  leadPhoneEnabled as leadPhoneEnabledAtom,
} from '../store';

export default function useCanLeadGen() {
  const [leadEnabled] = leadEnabledAtom.useState();
  const [leadNameEnabled] = leadNameEnabledAtom.useState();
  const [leadEmailEnabled] = leadEmailEnabledAtom.useState();
  const [leadPhoneEnabled] = leadPhoneEnabledAtom.useState();
  return leadEnabled && (leadNameEnabled || leadEmailEnabled || leadPhoneEnabled);
};