// https://codepen.io/shshaw/pen/bGNJJBE

import {
  forwardRef,
  type ChangeEvent,
  type KeyboardEvent,
  type Ref,
} from 'react';
import noop from '../utils/noop';
import { css } from '../css';
import styles from './textarea-autoresize.css?inline';

css(styles);

export default forwardRef(function TextAreaAutoResize({
  disabled = false,
  value = '',
  placeholder = '',
  minLength = 3,
  maxLength = 256,
  onChange = noop,
  onKeyDown = noop,
}: {
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  onChange?: (_e: ChangeEvent) => void;
  onKeyDown?: (_e: KeyboardEvent<HTMLTextAreaElement>) => void;
}, ref: Ref<HTMLTextAreaElement>) {
  return (
    <label className="textarea-autoresize" data-value={ value }>
      <textarea
        ref={ ref }
        rows={ 1 }
        value={ value }
        disabled={ disabled }
        placeholder={ placeholder }
        
        minLength={ minLength }
        maxLength={ maxLength }
        onChange={ onChange }
        onKeyDown={ onKeyDown }
      />
    </label>
  );
});