import cap from './cap.css?inline';
import { css } from '../css';
import {
  conversationCapMessage as conversationCapMessageAtom,
} from '../store';

css(cap);

export default function Cap() {
  const [text] = conversationCapMessageAtom.useState();
  return (
    <div className="cap">
      <p>
        { text }
      </p>
    </div>
  );
};