import { useState, useEffect } from 'react';
import { format, formatDistance } from 'date-fns';

const ONE_MINUTE = 60 * 1000;
const ONE_DAY = 24 * 60 * 60 * 1000;

const createDateMessage = function(date: Date) {
  const now = new Date();
  const difference = now.getTime() - date.getTime();
  if (difference < ONE_MINUTE) return 'just now';
  if (difference > ONE_DAY) return format(date, 'MMM dd');
  return `${formatDistance(date, now)} ago`;
};

const useTimeAgo = function(date: Date) {
  const [msg, setMsg] = useState(createDateMessage(date));
  useEffect(() => {
    const interval = setInterval(() => {
      setMsg(createDateMessage(date));
    }, ONE_MINUTE);
    
    return () => clearInterval(interval);
  }, []);
  return msg;
};

export default function TimeAgo({
  date,
  className,
}: {
  date: Date;
  className?: string;
}) {
  return (
    <span className={ className }>
      { useTimeAgo(date) }
    </span>
  );
};