import app from './app.css?inline';
import { css } from './css';
import MainToggle from './main-toggle/main-toggle';
import Assistant from './assistant/assistant';
import Greetings from './greetings/greetings';
import PoweredBy from './powered-by/powered-by';
import Chat from './chat/chat';
import Cap from './cap/cap';
import LeadGen from './lead-gen/lead-gen';
import useIsAtCap from './hooks/useIsAtCap';
import useCanLeadGen from './hooks/useCanLeadGen';
import {
  demo as demoAtom,
  leadPosition as leadPositionAtom,
  hasLeadCaptured as hasLeadCapturedAtom,
} from './store';

css(app);

export default function App() {
  const [hasLeadCaptured] = hasLeadCapturedAtom.useState();
  const [leadPosition] = leadPositionAtom.useState();
  const [isDemo] = demoAtom.useState();

  const isAtCap = useIsAtCap();
  const canLeadGen = useCanLeadGen();
  const shouldPreLeadGen = !hasLeadCaptured && canLeadGen && leadPosition === 'pre';
  const shouldPostLeadGen = !hasLeadCaptured && canLeadGen && leadPosition === 'post';

  const shouldShowLeadGen = !isDemo && (!isAtCap && shouldPreLeadGen) || (isAtCap && shouldPostLeadGen);
  const shouldShowChat = !isAtCap && !shouldShowLeadGen;

  return (
    <div className="app">
      <div className="app__main">
        { shouldShowChat && <Chat /> }
        { shouldShowChat && <Assistant /> }
        { shouldShowLeadGen && <LeadGen /> }
        { isAtCap && !shouldShowLeadGen && <Cap /> }
      </div>
      <div className="app__drawer">
        <Greetings />
        <MainToggle />
        <PoweredBy />
      </div>
    </div>
  );
};