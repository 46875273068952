import styles from './powered-by.css?inline';
import { css } from '../css';
import {
  poweredBy as poweredByAtom,
  isOpen as isOpenAtom,
} from '../store';

css(styles);

export default function PoweredBy() {
  const [enabled] = poweredByAtom.useState();
  const [isOpen] = isOpenAtom.useState();
  if (!enabled) return null;

  return (
    <p className="powered-by" data-open={ isOpen }>
      <span>Powered by </span>
      <a href="https://secre.tary.ai" target="_blank" rel="noopener noreferrer">
        secre.tary.ai
      </a>
    </p>
  );
};