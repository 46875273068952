import { useLayoutEffect, useRef, useEffect } from 'react';
import styles from './chat.css?inline';
import { css } from '../css';
import MessageBubble from './message-bubble';
import {
  isOpen as isOpenAtom,
  messages as messagesAtom,
  welcomeMessage as welcomeMessageAtom,
} from '../store';
import {
  AI,
  type Message as MessageType,
} from '@ai/shared/janet';

css(styles);

const Message = ({
  id,
  text,
  createdAt,
  loading,
  role,
}: MessageType) => {
  return (
    <MessageBubble
      id={ id }
      role={ role }
      text={ text }
      loading={ loading }
      createdAt={ createdAt }
    />
  );
};

// create an invalid date to use as the greeting
// so that it doesn't show up in the chat as e.g. "just now"
// but also fulfills the isValidDate check and other checks
// for this one manual case
const invalidDate = new Date('2013-13-32');

export default function Chat() {
  const [isOpen] = isOpenAtom.useState();
  const [messages] = messagesAtom.useState();
  const [greeting] = welcomeMessageAtom.useState();
  const scrollRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (scrollRef.current) scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, []);

  // https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/
  // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe#characterdata
  useEffect(() => {
    if (!scrollRef.current) return;
    
    const scrollElem = scrollRef.current;
    const childContainerElem = scrollElem.children[0];

    const onMutate = function() {
      scrollElem.scrollTop = scrollElem.scrollHeight;
    };

    const observer = new MutationObserver(onMutate);

    observer.observe(childContainerElem, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={ scrollRef }
      className="chat"
      data-open={ isOpen }
    >
      <div className="chat__box">
        { !!greeting && (
          <Message 
            id="janet-greeting"
            text={ greeting }
            createdAt={ invalidDate }
            loading={ false }
            role={ AI }
          />
        ) }
        { messages.map(msg => (
          <Message
            { ...msg }
            key={ msg.id }
          />
        )) }
      </div>
    </div>
  );
}