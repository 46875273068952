import { css } from '../css';
import mainToggle from './main-toggle.css?inline';
import Logo from '../icons/logo';
import { X } from 'lucide-react';
import { useEffect, useRef } from 'react';
import type { Handle } from './empathy.hooks';
import Empathy from './Empathy';
import {
  isOpen as isOpenAtom,
  loading as loadingAtom,
} from '../store';

css(mainToggle);

export default function MainToggle() {
  const [isOpen] = isOpenAtom.useState();
  const [loadingState] = loadingAtom.useState();
  const ref = useRef<Handle>();
  
  const isThinking = loadingState === 'loading';
  
  useEffect(() => {
    if (!ref.current) return;
    const { current: api } = ref;

    if (!isOpen) {
      api.ambient();
      return;
    }
    if (isOpen && !isThinking) {
      api.idle();
      return;
    }
    if (isOpen && isThinking) {
      api.think();
      return;
    }
  }, [isOpen, isThinking]);

  return (
    <button
      className="main-toggle"
      data-open={ isOpen }
      
      onClick={ e => {
        e.preventDefault();
        isOpenAtom.set(!isOpen);
      } }
    >
      <Logo className="main-toggle__logo" />
      <X className="main-toggle__close" />
      <Empathy ref={ ref } />
    </button>
  );
};