import { z } from 'zod';
import { nanoid } from 'nanoid';
import superjson from 'superjson';
import { messageSchema } from '@ai/shared/janet';
import { SESSION_KEY as KEY } from '../../constants';
import localStorage from './localStorage';

const sessionSchema = z.object({
  id: z.string(),
  isOpen: z.boolean(),
  hasGreeted: z.boolean(),
  hasLeadCaptured: z.boolean(),
  isGreetingClosed: z.boolean(),
  messages: z.array(messageSchema),
});

type SessionSchema = z.infer<typeof sessionSchema>;

export const set = (session: SessionSchema) => {
  localStorage.setItem(KEY, superjson.stringify(session));
};

const format = (session: SessionSchema): SessionSchema => {
  // format messages that have hung due to a network error
  // e.g. page refresh during request
  session.messages = session.messages.filter(message => {
    if (message.loading && !message.text) return false;
    if (message.loading && message.text) {
      message.loading = false;
      return true;
    }

    return true;
  });

  return session;
};

export const get = (): SessionSchema => {
  try {
    const entry = localStorage.getItem(KEY) || '';
    const stored = superjson.parse(entry);
    const result = sessionSchema.safeParse(stored);
    if (!result.success) throw new Error('invalid session');
    return format(result.data);
  } catch {}

  return {
    id: nanoid(),
    isOpen: false,
    hasGreeted: false,
    hasLeadCaptured: false,
    messages: [],
  };
};