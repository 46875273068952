import gsap from 'gsap';

type Timeline = gsap.core.Timeline;
type Ellipse = SVGEllipseElement;

const controls = (timelines: Timeline[], { timeScale }: { timeScale: number }) => {
  return {
    start() {
      timelines.forEach(tl => {
        tl.reversed(false);
        tl.repeat(-1)
          .timeScale(timeScale)
          .restart(true);
      });
      return this;
    },
    pause() {
      timelines.forEach(tl => tl.pause());
      return this;
    },
    async rest() {
      return Promise.all(
        timelines.map(tl => (
          tl.pause()
            .repeat(0)
            .reverse()
            .timeScale(2)
            .play()
        ))
      );
    },
  };
};

export type Control = ReturnType<typeof controls>;

const ambientAnimation = function(el: Ellipse, idx: number, ellipsis: Ellipse[]): Timeline {
  const count = idx + 1;
  
  const tl = gsap.timeline({
    repeat: -1,
    paused: true,
    defaults: { ease: 'sine.inOut' },
    delay: (idx / (ellipsis.length - 1)),
    restart: true,
  });

  tl.to(el, {
    attr: {
      ry: `-=${count * 2.3}`,
      rx: `+=${count * 1.4}`
    },
    ease: 'sine.in'
  })
    .to(el, {
      attr: {
        ry: `+=${count * 2.3}`,
        rx: `-=${count * 1.4}`
      },
      ease: 'sine'
    })
    .to(el, {
      duration: 1,
      rotation: -180,
      transformOrigin: '50% 50%',
    }, 0);

  return tl;
};

const idleAnimation = function(el: Ellipse, idx: number, ellipsis: Ellipse[]): Timeline {
  const count = idx + 1;

  const tl = gsap.timeline({
    repeat: -1,
    paused: true,
    defaults: { ease: 'sine.inOut' },
    delay: (idx / (ellipsis.length - 1)),
  });

  tl.to(el, {
    attr: {
      ry: `+=${count}`,
      rx: `+=${count}`,
    },
    ease: 'sine.in'
  })
    .to(el, {
      attr: {
        rx: `-=${count}`,
        ry: `-=${count}`,
      },
      ease: 'sine'
    });

  return tl;
};

const thinkAnimation = function(el: Ellipse, idx: number, ellipsis: Ellipse[]): Timeline {
  const count = idx + 1;

  const tl = gsap.timeline({
    repeat: -1,
    paused: true,
    defaults: { ease: 'sine.inOut' },
    delay: (idx / (ellipsis.length - 1)),
  });

  tl.to(el, {
    attr: {
      ry: `+=${count * -1.35}`,
      rx: `+=${count * -1.1}`,
    },
    ease: 'sine.in'
  })
    .to(el, {
      attr: {
        ry: `-=${count * -1.35}`,
        rx: `-=${count * -1.1}`,
      },
      ease: 'sine'
    })
    .to(el, {
      duration: 1,
      rotation: 360,
      transformOrigin: '50% 50%',
    }, 0);

  return tl;
};

export const animation = function(svg: SVGElement) {
  const ellipsis = Array.from(svg.querySelectorAll('ellipse'));

  const ambient = controls(ellipsis.map(ambientAnimation), { timeScale: 0.5 });
  const idle = controls(ellipsis.map(idleAnimation), { timeScale: 0.5 });
  const think = controls(ellipsis.map(thinkAnimation), { timeScale: 1 });
  
  return {
    ambient,
    idle,
    think,
  };
};

export type Animation = ReturnType<typeof animation>;