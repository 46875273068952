import { useRef, useEffect, useImperativeHandle, Ref } from 'react';
import { animation, Control } from './empathy.animation';

const createHandle = function(animRef: { current: ReturnType<typeof animation> | null }) {
  let current: Control;
  return {
    async ambient() {
      if (!animRef.current) return;
      const { current: anim } = animRef;
      if (current) await current.rest();
      current = anim.ambient.start();
    },
    async idle() {
      if (!animRef.current) return;
      const { current: anim } = animRef;
      if (current) await current.rest();
      current = anim.idle.start();
    },
    async think() {
      if (!animRef.current) return;
      const { current: anim } = animRef;
      if (current) await current.rest();
      current = anim.think.start();
    },
  };
};

export const useEmpathy = function(handle: Ref<unknown> | undefined) {
  const svgRef = useRef<SVGSVGElement>(null);
  const animRef = useRef<ReturnType<typeof animation> | null>(null);

  useImperativeHandle(handle, () => {
    let current: Control;
    return {
      async ambient() {
        if (!animRef.current) return;
        const { current: anim } = animRef;
        if (current) await current.rest();
        current = anim.ambient.start();
      },
      async idle() {
        if (!animRef.current) return;
        const { current: anim } = animRef;
        if (current) await current.rest();
        current = anim.idle.start();
      },
      async think() {
        if (!animRef.current) return;
        const { current: anim } = animRef;
        if (current) await current.rest();
        current = anim.think.start();
      },
    };
  }, []);

  useEffect(() => {
    if (!svgRef.current) return;
    animRef.current = animation(svgRef.current);
  }, []);

  return svgRef;
};

export type Handle = ReturnType<typeof createHandle>;