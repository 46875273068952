import { Fragment } from 'react';

const regexImpl = () => /\[([^\]]+)\]\(([^)]+)\)/g;

const Link = ({ href, children }: { href: string; children: string; }) => {
  return (
    <a
      href={ href }
      target="_blank"
      rel="noopener noreferrer"
    >
      { children }
    </a>
  );
};

export type DynamicText = (string | { href: string; children: string; })[];

export const create = function(text: string): DynamicText {
  const regex = regexImpl();
  
  let match;
  let lastIndex = 0;
  const result = [];

  while ((match = regex.exec(text)) !== null) {
    if (match.index !== lastIndex) {
      result.push(text.slice(lastIndex, match.index));
    }
  
    const [, children, href] = regexImpl().exec(match[0]) || ['', '', ''];
    result.push({ children, href });
    lastIndex = match.index + match[0].length;
  }

  if (lastIndex !== text.length) {
    result.push(text.slice(lastIndex));
  }

  return result
    .map(item => {
      if (typeof item === 'string') return item.split(' ');
      return item;
    })
    .flat();
};

export default function DynamicTextRender({
  text,
}: {
  text: DynamicText;
}) {
  return text.map((item, idx) => {
    return (
      <Fragment key={ idx }>
        { typeof item === 'string'
          ? item
          : <Link { ...item } /> }
        { idx === text.length - 1 ? '' : ' ' }
      </Fragment>
    );
  });
}