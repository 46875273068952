import {
  colorScheme as colorSchemeAtom,
} from '../index';

export const get = (): 'light' | 'dark' => {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'dark';
  }
  return 'light';
};

export const watch = () => {
  const onChange = (event: { matches: boolean }) => {
    colorSchemeAtom.set(event.matches ? 'dark' : 'light');
  };

  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', onChange);

  return () => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', onChange);
  };
};