import { atom } from './atom';
import {
  type Sizes,
  type Margins,
  type ColorSchemes,
  type LeadPosition,
  type Message,
} from '@ai/shared/janet';

export const isValid = atom<boolean>(false);
export const demo = atom<boolean>(false);

export type LoadingStates = 'loading' | 'done' | 'errored';
export const loading = atom<LoadingStates>('done');

export type Token = string;
export const token = atom<Token>('');

export const isOpen = atom<boolean>(false);
export const isOnline = atom<boolean>(globalThis.navigator.onLine);

export const domainId = atom<string>('');
export const sessionId = atom<string>('');

export const size = atom<Sizes>('md');
export const margin = atom<Margins>('md');
export const colorScheme = atom<ColorSchemes>('auto');
export const placeholder = atom<string>('');
export const poweredBy = atom<boolean>(true);
export const zIndex = atom<number>(9999);
export const welcomeMessage = atom<string>(``);
export const welcomeDelay = atom<number>(1000);
export const hasGreeted = atom<boolean>(true);
export const isGreetingClosed = atom<boolean>(false);
export const conversationCap = atom<number>(10);
export const conversationCapMessage = atom<string>('');

export const leadEnabled = atom<boolean>(true);
export const leadPosition = atom<LeadPosition>('pre');
export const leadNameEnabled = atom<boolean>(false);
export const leadEmailEnabled = atom<boolean>(false);
export const leadPhoneEnabled = atom<boolean>(false);
export const leadHeading = atom<string>('');
export const leadMessage = atom<string>('');

export const hasLeadCaptured = atom<boolean>(false);

export const messages = atom<Message[]>([]);

export { initialize } from './initialize';