import { useRef, useCallback, useEffect, useState } from 'react';
import { css } from '../css';
import greetings from './greetings.css?inline';
import { useInterval } from '../hooks/useInterval';
import { X } from 'lucide-react';
import {
  messages as messagesAtom,
  hasGreeted as hasGreetedAtom,
  isGreetingClosed as isGreetingClosedAtom,
  isOpen as isOpenAtom,
  welcomeDelay as welcomeDelayAtom,
  welcomeMessage as welcomeMessageAtom,
} from '../store';

css(greetings);

export default function Greetings() {
  const [shouldRender, setShouldRender] = useState(false);
  const [messages] = messagesAtom.useState();
  const [isOpen] = isOpenAtom.useState();
  const [isDismissed] = isGreetingClosedAtom.useState();
  const [hasGreeted, setHasGreeted] = hasGreetedAtom.useState();
  const [message] = welcomeMessageAtom.useState();
  const [welcomeDelay] = welcomeDelayAtom.useState();

  const hasMessages = !!messages.length;
  const hasOpenedRef = useRef(isOpen);
  const hasOpened = hasOpenedRef.current;
  const isHidden = hasMessages || hasOpened || isOpen || !shouldRender;

  const shouldDelay = !hasOpened && !hasGreeted;
  const delay = shouldDelay ? welcomeDelay : 0;

  // track if we have opened the drawer
  // and dont show if we have
  useEffect(() => {
    if (isOpen) hasOpenedRef.current = true;
  }, [isOpen]);

  // on mount, mark that we have greeted
  const onGreet = useCallback(() => {
    setShouldRender(true);
  }, []);
  useInterval(onGreet, delay);

  const onDismiss = useCallback(() => {
    isGreetingClosedAtom.set(true);
  }, []);

  const onAnimationEnd = useCallback(() => {
    setHasGreeted(true);
  }, []);

  // everything is moot if there's no message to display
  if (!message) return null;

  return (
    <div
      className="greetings"
      data-hidden={ isHidden }
      data-animate={ !isHidden && !hasGreeted }
      data-dismissed={ isDismissed }
      onAnimationEnd={ onAnimationEnd }
    >
      { message }
      <button
        className="greetings__close"
        onClick={ onDismiss }
      >
        <X />
      </button>
    </div>
  );
};