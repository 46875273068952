import { type InputHTMLAttributes } from 'react';
import { css } from '../css';
import leadGenInput from './lead-gen-input.css?inline';

css(leadGenInput);

export default function LeadGenInput(props: InputHTMLAttributes<HTMLInputElement>) {
  return (
    <input
      className="lead-gen-input"
      { ...props }
    />
  );
};