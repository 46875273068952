import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { createStore, atom as jotaiAtom } from 'jotai';
import { Signal } from '../utils/signal';

export const atom = function<T>(initialValue: T) {
  const store = createStore();
  const depotAtom = jotaiAtom<T>(initialValue);
  const signal = Signal();

  return Object.assign(signal, {
    get(): T {
      return store.get(depotAtom);
    },

    set(value: T) {
      const isDirty = store.get(depotAtom) !== value;
      store.set(depotAtom, value);
      if (isDirty) signal.emit('change', value);
    },

    useState(): [T, (_: T) => void] {
      const [value, set] = useState(store.get(depotAtom));
      
      useEffect(() => {
        return store.sub(depotAtom, () => {
          const value = store.get(depotAtom);
          set(value);
        });
      }, []);
      
      const setValue = useCallback((value: T) => {
        this.set(value);
        return value;
      }, []) satisfies (_: T) => void;

      return [value, setValue];
    },
  });
};